import * as yup from "yup"

export const regex = {
  singleName: /^[a-zA-Zא-ת\s'-]*$/,
  phoneNumber: /^05\d([-]{0,1})\d{7}$/,
  allPhoneNumber: /^(?:\+972|0)(?:5\d|[2-9]|7\d)\d{7}$/,
  IDCompanyId: /^(?:[0-9]{5,9}|51\d{7})$/,
  linkedin: /^https:\/\/www\.linkedin\.com\/in\//,
  email:
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
}

export const invalid = {
  firstName: "יש להקליד אותיות בעברית או באנגלית בלבד וללא סימנים מיוחדים",
  lastName: "יש להקליד אותיות בעברית או באנגלית בלבד וללא סימנים מיוחדים",
  phoneNumber: "מספר נייד לא תקין",
  email: "כתובת האימייל אינה תקינה",
  allPhoneNumber: "מספר טלפון לא תקין",
  description: "כאן צריך לכתוב בקצרה מדוע החלטת לשלוח פנייה",
  ID: 'מספר ת"ז/ח"פ לא תקין',
  linkedin: "הקישור אינו תקינה",
  subject: "subject error",
}

export const schemaDonationRequest = {
  stringValue: yup.string().min(1).max(50).required("required"),
  booleanValue: yup.string().max(50).required("required"),
  numberValue: yup
    .string()
    .matches(/^[0-9]+$/)
    .required("required"),
}

export const messagesInvalid = {
  en: {
    firstName: "Letters must be typed in Hebrew or English only and without special symbols",
    phoneNumber: "The phone number entered is incorrect",
    email: "The email address entered is invalid",
    requiredName: "First name and last name must be filled in",
    requiredPhoneNumber: "A phone number must be entered",
    requiredEmail: "An email address must be entered",
    subject: "You must choose subject",
  },
  he: {
    firstName: "יש להקליד אותיות בעברית או באנגלית בלבד וללא סימנים מיוחדים",
    phoneNumber: "מספר הטלפון שהוזן אינו תקין",
    email: "כתובת האימייל שהוזנה אינה תקינה",
    requiredName: "יש למלא שם פרטי ושם משפחה",
    requiredPhoneNumber: "יש להזין מספר טלפון",
    requiredEmail: "יש להזין כתובת מייל",
    subject: "עליך לבחור נושא",
  },
}

export const messagesInvalid4Rent = {
  en: {
    firstName: "Only Hebrew and English letters allowed",
    phoneNumber: "The phone number entered is incorrect",
    email: "Proper email structure (e.g., example@email.com).",
    requiredName: "Field must be filled",
    requiredPhoneNumber: "Field must be filled",
    requiredEmail: "Field must be filled",
    subject: "You must choose subject",
  },
  he: {
    firstName: "ניתן להזין רק אותיות עברית ואנגלית",
    phoneNumber: "מספר הטלפון שהוזן אינו תקין",
    email: "מבנה כתובת אימייל חוקי (לדוגמה: example@email.com)",
    requiredName: "חובה למלא את השדה",
    requiredPhoneNumber: "חובה למלא את השדה",
    requiredEmail: "חובה למלא את השדה",
    subject: "עליך לבחור נושא",
  },
}
type Language = "en" | "he"

export const schemaCommon = {
  firstName: yup
    .string()
    .matches(regex.singleName, invalid.firstName)
    .max(25, invalid.firstName)
    .required("אנחנו צריכים לדעת מה השם שלך"),
  lastName: yup
    .string()
    .matches(regex.singleName, invalid.lastName)
    .max(25, invalid.lastName)
    .required("צריך למלא גם שם משפחה"),
  fullName: yup
    .string()
    .matches(regex.singleName, invalid.firstName)
    .max(50, invalid.firstName)
    .required("אנחנו צריכים לדעת מה השם שלך"),
  ID: yup.string().matches(regex.IDCompanyId, invalid.ID).required('אנא הקלד ת"ז/ח"פ'),
  phoneNumber: yup
    .string()
    .matches(regex.phoneNumber, invalid.phoneNumber)
    .length(10, invalid.phoneNumber)
    .required("מה מספר הנייד שלך? צריך למלא, כדי שנחזור אליך")
    .max(10, invalid.phoneNumber),
  city: yup.string().required("צריך לנסות להקליד את שם העיר שוב"),
  email: yup
    .string()
    .matches(regex.email, invalid.email)
    .required("אנחנו צריכים לקבל את כתובת האימייל שלך"),
  allPhoneNumber: yup
    .string()
    .matches(regex.allPhoneNumber, invalid.allPhoneNumber)
    .required("מה המספר שלך? צריך למלא, כדי שנחזור אליך")
    .max(13, invalid.allPhoneNumber),
  description: yup.string().required(invalid.description).max(500, invalid.description),
  comments: yup.string().nullable().notRequired(),
  subject: yup.string().min(1).required(),
  file: yup.string().required("required"),
  fileName: yup.string().required("required"),
  linkedin: yup.string().matches(regex.linkedin, invalid.linkedin),
  subscrube: yup.boolean().required(),
  jobId: yup.string().required(),
}

export const generateSchema = (language: Language) => {
  const msg = messagesInvalid[language]

  return yup.object({
    fullName: yup
      .string()
      .matches(regex.singleName, msg.firstName)
      .max(50, msg.firstName)
      .required(msg.requiredName),
    email: yup.string().matches(regex.email, msg.email).required(msg.requiredEmail),
    phone: yup
      .string()
      .matches(regex.allPhoneNumber, msg.phoneNumber)
      .required(msg.requiredPhoneNumber)
      .max(13, msg.phoneNumber),
    message: yup.string().nullable().notRequired(),
    subject: yup.string().min(1, msg.subject).required(msg.subject),
  })
}

export const generateSchema4Rent = (language: Language) => {
  const msg = messagesInvalid4Rent[language]

  return yup.object({
    fullName: yup
      .string()
      .matches(regex.singleName, msg.firstName)
      .min(2, msg.firstName)
      .max(50, msg.firstName)
      .required(msg.requiredName),
    email: yup.string().required(msg.requiredEmail).matches(regex.email, msg.email),
    phone: yup
      .string()
      .required(msg.requiredPhoneNumber)
      .matches(regex.allPhoneNumber, msg.phoneNumber)
      .max(13, msg.phoneNumber),
    message: yup.string().nullable().notRequired(),
    subject: yup.string().min(1, msg.subject).required(msg.subject),
  })
}
export const generateGenericSchema = (language: Language) => {
  const msg = messagesInvalid[language]

  return yup.object({
    fullName: yup
      .string()
      .matches(regex.singleName, msg.firstName)
      .max(50, msg.firstName)
      .required(msg.requiredName),
    email: yup.string().matches(regex.email, msg.email).required(msg.requiredEmail),
    phone: yup
      .string()
      .matches(regex.allPhoneNumber, msg.phoneNumber)
      .required(msg.requiredPhoneNumber)
      .max(13, msg.phoneNumber),
    message: yup.string().nullable().notRequired(),
  })
}
