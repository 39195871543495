import { SVGProps } from "react"
const NewWindowIconEn = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.66797 6.27536C1.66797 4.49651 3.10968 3.05566 4.88766 3.05566L7.70458 3.05566C8.08812 3.05566 8.39902 3.36658 8.39902 3.75011C8.39902 4.13364 8.08812 4.44455 7.70458 4.44455L4.88766 4.44455C3.87651 4.44455 3.05686 5.2638 3.05686 6.27536L3.05686 15.1137C3.05686 16.1252 3.87651 16.9446 4.88766 16.9446H13.726C14.7371 16.9446 15.5569 16.1252 15.5569 15.1137V12.2981C15.5569 11.9146 15.8678 11.6037 16.2513 11.6037C16.6348 11.6037 16.9457 11.9146 16.9457 12.2981V15.1137C16.9457 16.8926 15.5041 18.3334 13.726 18.3334H4.88766C3.10968 18.3334 1.66797 16.8926 1.66797 15.1137L1.66797 6.27536Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.3906 2.36119C11.3906 1.97766 11.7015 1.66675 12.0851 1.66675L17.6406 1.66675C18.0241 1.66675 18.3351 1.97766 18.3351 2.36119V7.91675C18.3351 8.30028 18.0241 8.61119 17.6406 8.61119C17.2572 8.61119 16.9462 8.30028 16.9462 7.91675V3.05564L12.0851 3.05564C11.7015 3.05564 11.3906 2.74472 11.3906 2.36119Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.6238 2.39878C17.8946 2.67041 17.8939 3.11011 17.6222 3.38087L11.1847 9.79754C10.9131 10.0683 10.4734 10.0676 10.2026 9.79594C9.93185 9.5243 9.93256 9.08461 10.2042 8.81385L16.6417 2.39718C16.9133 2.12643 17.3531 2.12714 17.6238 2.39878Z"
      fill="black"
    />
  </svg>
)
export default NewWindowIconEn
