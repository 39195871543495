import { SVGProps } from "react"

const DownloadIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.7271 5.72728L4.09073 5.72728C2.28325 5.72728 0.818004 7.19253 0.818004 9.00001L0.818004 13.0909C0.818004 14.8984 2.28325 16.3636 4.09073 16.3636L13.9089 16.3636C15.7164 16.3636 17.1816 14.8984 17.1816 13.0909L17.1816 9.00001C17.1816 7.19253 15.7164 5.72728 13.9089 5.72728L12.2725 5.72728"
        stroke="black"
        stroke-width="1.22727"
        stroke-linecap="round"
      />
      <path
        d="M5.7271 5.72728L4.09073 5.72728C2.28325 5.72728 0.818004 7.19253 0.818004 9.00001L0.818004 13.0909C0.818004 14.8984 2.28325 16.3636 4.09073 16.3636L13.9089 16.3636C15.7164 16.3636 17.1816 14.8984 17.1816 13.0909L17.1816 9.00001C17.1816 7.19253 15.7164 5.72728 13.9089 5.72728L12.2725 5.72728"
        stroke="black"
        stroke-opacity="0.2"
        stroke-width="1.22727"
        stroke-linecap="round"
      />
      <path
        d="M5.7271 5.72728L4.09073 5.72728C2.28325 5.72728 0.818004 7.19253 0.818004 9.00001L0.818004 13.0909C0.818004 14.8984 2.28325 16.3636 4.09073 16.3636L13.9089 16.3636C15.7164 16.3636 17.1816 14.8984 17.1816 13.0909L17.1816 9.00001C17.1816 7.19253 15.7164 5.72728 13.9089 5.72728L12.2725 5.72728"
        stroke="black"
        stroke-opacity="0.2"
        stroke-width="1.22727"
        stroke-linecap="round"
      />
      <path
        d="M5.7271 5.72728L4.09073 5.72728C2.28325 5.72728 0.818004 7.19253 0.818004 9.00001L0.818004 13.0909C0.818004 14.8984 2.28325 16.3636 4.09073 16.3636L13.9089 16.3636C15.7164 16.3636 17.1816 14.8984 17.1816 13.0909L17.1816 9.00001C17.1816 7.19253 15.7164 5.72728 13.9089 5.72728L12.2725 5.72728"
        stroke="black"
        stroke-opacity="0.2"
        stroke-width="1.22727"
        stroke-linecap="round"
      />
      <path
        d="M6.5459 9.81819L8.4219 11.6942C8.74142 12.0137 9.25947 12.0137 9.57899 11.6942L11.455 9.81819"
        stroke="black"
        stroke-width="1.22727"
        stroke-linecap="round"
      />
      <path
        d="M6.5459 9.81819L8.4219 11.6942C8.74142 12.0137 9.25947 12.0137 9.57899 11.6942L11.455 9.81819"
        stroke="black"
        stroke-opacity="0.2"
        stroke-width="1.22727"
        stroke-linecap="round"
      />
      <path
        d="M6.5459 9.81819L8.4219 11.6942C8.74142 12.0137 9.25947 12.0137 9.57899 11.6942L11.455 9.81819"
        stroke="black"
        stroke-opacity="0.2"
        stroke-width="1.22727"
        stroke-linecap="round"
      />
      <path
        d="M6.5459 9.81819L8.4219 11.6942C8.74142 12.0137 9.25947 12.0137 9.57899 11.6942L11.455 9.81819"
        stroke="black"
        stroke-opacity="0.2"
        stroke-width="1.22727"
        stroke-linecap="round"
      />
      <path
        d="M9.00098 11.4545L9.00098 1.63637"
        stroke="black"
        stroke-width="1.22727"
        stroke-linecap="round"
      />
      <path
        d="M9.00098 11.4545L9.00098 1.63637"
        stroke="black"
        stroke-opacity="0.2"
        stroke-width="1.22727"
        stroke-linecap="round"
      />
      <path
        d="M9.00098 11.4545L9.00098 1.63637"
        stroke="black"
        stroke-opacity="0.2"
        stroke-width="1.22727"
        stroke-linecap="round"
      />
      <path
        d="M9.00098 11.4545L9.00098 1.63637"
        stroke="black"
        stroke-opacity="0.2"
        stroke-width="1.22727"
        stroke-linecap="round"
      />
    </svg>
  )
}
export default DownloadIcon
