import { ApplyRequest } from "api"
import axios from "axios"

const fetchJobsUrl = process.env.API_URL + "/web/career/jobs/"
const fetchAreasUrl = process.env.API_URL + "/web/career/areas/"
const fetchCompaniesUrl = process.env.API_URL + "/web/career/companies/"
const fetchJobScopesUrl = process.env.API_URL + "/web/career/jobScopes/"
const fetchProfessionsUrl = process.env.API_URL + "/web/career/professions/"
const fetchSubjectOptionsUrl = process.env.API_URL + "/web/contactUs/subjects/"
const postContactUsUrl = process.env.API_URL + "/web/contactUs/sendContactUs/"
const postApplyUrl = process.env.API_URL + "/web/career/apply"
const fetchJobsLobbyUrl = process.env.API_URL + "/web/career/jobsLobby"

export interface Job {
  orderId: number
  jobName: string | null
  professionName: string
  areaId: number
  areaName: string | null
  startAdvertisingDate: string
  clientName: string | null
  clientParentId: number | null
  jobScopeId: number | null
  branchId: number | null
  subfield: string | null
  department: string | null
  professionId: number
  scopeJobId: number
  scopeJobName: string | null
  companyName: string | null
  description: string | null
}

export interface JobsObject {
  pageIndex: number
  pageSize: number
  totalCount: number
  totalPageCount: number
  items: Job[]
  hasPreviousPage: boolean
  hasNextPage: boolean
}

export interface JobDto {
  orderId: number /* int32 */
  jobName: string
  professionId: number /* int32 */
  professionName: string
  areaId: number /* int32 */
  areaName: string
  scopeJobId: number /* int32 */
  scopeJobName: string
  companyId: number /* int32 */
  companyName: string
  startAdvertisingDate: string /* date-time */
  description: string
}

type FetchResponse<T> = {
  custom: string | null
  errorCode: number
  errorMessage: string | null
  body: T
}

type FetchFilterResponse = FetchResponse<{ id: number; name: string | null }[]>
type FetchJobsResponse = FetchResponse<JobsObject | null>
type SingleJobResponse = FetchResponse<JobDto | null>
type FetchSubjectsOptions = FetchResponse<Record<string, string>>

type Props = {
  requestPage?: unknown
  pageSize?: number
  professionIds?: number
  areaIds?: number
  companyIds?: any
  jobScopeIds?: any
  orderIds?: any
  companyDivisions?: any
}

export async function fetchJobsLobby({
  pageSize = 10,
  requestPage = 0,
  areaIds,
  companyIds,
  jobScopeIds,
  orderIds,
  professionIds,
  companyDivisions,
}: Props) {
  try {
    const response = await axios.post(fetchJobsLobbyUrl, {
      requestPage,
      pageSize,
      areaIds,
      companyIds,
      jobScopeIds,
      professionIds,
      orderIds,
      companyDivisions,
    })

    const { data }: { data: any } = response
    if (data.body === null) {
      console.error(data)
      throw new Error("Couldn't fetch jobs")
    }
    return data.body
  } catch (err) {
    throw err
  }
}

export async function fetchOnlyJobs({
  pageSize = 9,
  requestPage = 0,
  areaIds,
  companyIds,
  jobScopeIds,
  orderIds,
  professionIds,
  companyDivisions,
}: Props) {
  try {
    const response = await axios.post(fetchJobsLobbyUrl, {
      requestPage,
      pageSize,
      areaIds,
      companyIds,
      jobScopeIds,
      professionIds,
      orderIds,
      companyDivisions,
    })

    const { data }: { data: any } = response
    if (data.body === null) {
      throw new Error("Couldn't fetch jobs")
    }
    return data.body.jobs
  } catch (err) {
    throw err
  }
}

export async function fetchJobs(orderId: string) {
  try {
    const response = await axios.get(fetchJobsUrl + orderId)
    const { data }: { data: SingleJobResponse } = response

    if (data.body === null) {
      throw new Error("Couldn't fetch jobs")
    }

    return data.body
  } catch (err) {
    throw err
  }
}

export interface JobFilter {
  label: string
  options: {
    id: number
    name: string | null
  }[]
}

export interface JobFilters {
  areaId: JobFilter
  companyId: JobFilter
  jobScopeId: JobFilter
  professionId: JobFilter
}

type Messages = {
  findJob: string
  company: string
  all: string
  locaiton: string
  jobType: string
  jobCategory: string
  requiredFields: string
  jobsBoard: string
  viewResults: string
  jobNo: string
  jobDescr: string
  viewJob: string
  more: string
}
export async function fetchFilters(messages: Messages): Promise<JobFilters> {
  const urls = [fetchAreasUrl, fetchCompaniesUrl, fetchJobScopesUrl, fetchProfessionsUrl]

  const promises = urls.map((url) =>
    axios.get<FetchFilterResponse>(url).then((response) => response.data.body),
  )

  const [areasBody, companiesBody, jobScopesBody, professionsBody] = await Promise.all(promises)
  return {
    areaId: {
      label: messages.locaiton,
      options: [{ id: 0, name: messages.all }, ...areasBody],
    },
    companyId: {
      label: messages.company,
      options: [{ id: 0, name: messages.all }, ...companiesBody],
    },
    jobScopeId: {
      label: messages.jobType,
      options: [{ id: 0, name: messages.all }, ...jobScopesBody],
    },
    professionId: {
      label: messages.jobCategory,
      options: [{ id: 0, name: messages.all }, ...professionsBody],
    },
  }
}

export async function fetchSubjectOptions(companyId = 1) {
  try {
    const response = await axios.get(fetchSubjectOptionsUrl + companyId)
    const { data }: { data: FetchSubjectsOptions } = response
    if (data.body === null) {
      throw new Error("Couldn't fetch jobs")
    }
    return data.body
  } catch (err) {
    throw err
  }
}

type ContactUsProps = {
  contactUsSubject: string
  companyId: number
  senderName: string
  senderPhone: string
  senderEmail: string
  content?: string
}

export async function postContactUs(object: ContactUsProps) {
  const { companyId, contactUsSubject, content, senderEmail, senderName, senderPhone } = object
  try {
    const response = await axios.post(postContactUsUrl, object)
    const { data }: { data: FetchJobsResponse } = response
    if (data.body === null) {
      throw new Error("Couldn't post form")
    }
    return data.body
  } catch (err) {
    throw err
  }
}

type ApplyToJob = {
  firstName: string
  lastName: string
  email: string
  phone: string
  cvFileBase64: string
  fileNameWithExtension: string
  isApprovedTerms: boolean
  orderId: number
}

export async function postApply(object: ApplyRequest) {
  try {
    const response = await axios.post(postApplyUrl, object)
    const { data }: { data: FetchJobsResponse } = response
    if (data.body === null) {
      throw new Error("Couldn't post form")
    }
    return data.body
  } catch (err) {
    throw err
  }
}
