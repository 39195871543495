import { SVGProps } from "react"
const NewWindowIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.332 6.77536C18.332 4.99651 16.8903 3.55566 15.1123 3.55566H12.2954C11.9119 3.55566 11.601 3.86658 11.601 4.25011C11.601 4.63364 11.9119 4.94455 12.2954 4.94455H15.1123C16.1235 4.94455 16.9431 5.7638 16.9431 6.77536V15.6137C16.9431 16.6252 16.1235 17.4446 15.1123 17.4446H6.27398C5.26287 17.4446 4.44314 16.6252 4.44314 15.6137V12.7981C4.44314 12.4146 4.13217 12.1037 3.7487 12.1037C3.36523 12.1037 3.05425 12.4146 3.05425 12.7981V15.6137C3.05425 17.3926 4.49592 18.8334 6.27398 18.8334H15.1123C16.8903 18.8334 18.332 17.3926 18.332 15.6137V6.77536Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.60938 2.86119C8.60938 2.47766 8.29846 2.16675 7.91493 2.16675H2.35937C1.9759 2.16675 1.66493 2.47766 1.66493 2.86119V8.41675C1.66493 8.80028 1.9759 9.11119 2.35937 9.11119C2.74285 9.11119 3.05382 8.80028 3.05382 8.41675V3.55564H7.91493C8.29846 3.55564 8.60938 3.24472 8.60938 2.86119Z"
      fill="black"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.37625 2.89878C2.10541 3.17041 2.10611 3.61011 2.37778 3.88087L8.81531 10.2975C9.08694 10.5683 9.52664 10.5676 9.7974 10.2959C10.0682 10.0243 10.0674 9.58461 9.7958 9.31385L3.35833 2.89718C3.08667 2.62643 2.64694 2.62714 2.37625 2.89878Z"
      fill="black"
    />
  </svg>
)
export default NewWindowIcon
