export const sharedColors = {
  black: "#000000",
  white: "#FFFFFF",
  red: "#FF0000",
  darkRed: "#D10040",
  green: "#00FF00",
  darkBlue: "#153E69",
  primaryDarkBlue: "#1A2B68",
  bluishGray: "#BAD2D4",
  propBlue: "#2768A3",
  yellow: "#FCBB00",
  gray: "#6F6F6F",
  lightGray: "#6F6F6F",
  disableGray: "#696F7A",
  mint: "#040B14",
  blue: "#0050C9",
  lighterBlack: "#666666",
  darkGray: "#5C6474",
  lightGreen: "#66CC00",
  sliderBlue: "#2642A6",
  darkestBlue: "#1B1F2F",
  blueMain: "#0916BC",
  lightGrey: "#F7F7F7",
  corpBlue: "#2768A3",
  darkGreen: "rgba(91, 117, 83, 1)",
  bonitaFooter: "#4A4A4A",
  boniteDarkBlue: "#0D0630",
  heroBackgroundEn: `linear-gradient(180deg, #1d4973 0%, #3684cc 116.35%)`,
  heroBackgroundHe: `linear-gradient(180deg, #1d4973 100%, #3684cc 116.35%)`,
  heroTextColor: "#FFF",
  heroSvgIconColor: "#FFF",
  filterModalHeaderColor: "#2768A3",
  filterModalHeaderTextColor: "#FFF",
  footerColor: "#2768A3",
  socialColor: "#FFF",
  footerTextColor: "#FFF",
  faqRootBackground: "linear-gradient(90deg, #1d4973 0%, #317bbf 60.44%, #3684cc 100%)",
  faqRootInsideBackground: "white",
  faqBorderColor: "#2768A3",
  faqTextColor: "#FFF",
  faqIconColor: "#2768A3",
  faqIconColorAlternative: "#FFF",
  faqBoxShadow: "0px 4px 12px 0px rgba(0, 0, 0, 0.08)",
  navBackgroundColor: "white",
  navTextColor: "black",
  selectedNavTextColor: "black",
  selectedSubNavTextColor: "#2768A3",
  selectedMobileNavTextColor: "#2768A3",
  footerSocialsBackgroundColor: "#2768A3",
  footerLogoLinkBackgroundColor: "#2768A3",
  numberGridBackgroundColorEn: "linear-gradient(90deg, #3684cc 0%, #317bbf 60.44%, #1d4973 100%)",
  numberGridBackgroundColorHe: "linear-gradient(90deg, #1d4973 0%, #317bbf 60.44%, #3684cc 100%)",
  numberGridBorderColor: "#2768a3",
  numberGridTextColor: "white",
  accordionPanelBackgroundColor: "rgba(44, 91, 163, 0.4)",
  accordionPanelOpenBackgroundColor:
    "linear-gradient(180deg,rgba(0, 0, 0, 0) 0%,rgba(0, 0, 0, 0) 30%,rgba(0, 0, 0, 0.6) 100%)",
  jobsQuoteGalleryBackgroundColor: "#2768A3",
  jobsQuoteGalleryBulletColor: "white",
  jobsGalleryItemTextColor: "white",
  teamContainerBackgroundColor: "#2768A3",
  teamContainerTextColor: "white",
  paginationBackgroundColor: "#2768A3",
  paginationTextColor: "white",
  bulletsBackgoundColor: "#2768A3",
  buttonHoverBackgroundColor: "#2768A3",
  buttonActiveBackgroundColor: "#2768A3",
  buttonHoverTextColor: "white",
  marketingBannerBackground: "#e0f3ff",
  marketingBannerTextColor: "black",
}
