const GalleryIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.61539 19.3846L5 19.3846C4.44772 19.3846 4 18.9369 4 18.3846L4 14.7692M4.76918 18.6155L10.1538 13.2309M19.3844 8.61516L19.3844 4.99977C19.3844 4.44749 18.9367 3.99977 18.3844 3.99977L14.769 3.99977M18.6152 4.76904L13.2305 10.1537"
      stroke="black"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <path
      d="M8.61539 19.3846L5 19.3846C4.44772 19.3846 4 18.9369 4 18.3846L4 14.7692M4.76918 18.6155L10.1538 13.2309M19.3844 8.61516L19.3844 4.99977C19.3844 4.44749 18.9367 3.99977 18.3844 3.99977L14.769 3.99977M18.6152 4.76904L13.2305 10.1537"
      stroke="black"
      stroke-opacity="0.2"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <path
      d="M8.61539 19.3846L5 19.3846C4.44772 19.3846 4 18.9369 4 18.3846L4 14.7692M4.76918 18.6155L10.1538 13.2309M19.3844 8.61516L19.3844 4.99977C19.3844 4.44749 18.9367 3.99977 18.3844 3.99977L14.769 3.99977M18.6152 4.76904L13.2305 10.1537"
      stroke="black"
      stroke-opacity="0.2"
      stroke-width="1.5"
      stroke-linecap="round"
    />
    <path
      d="M8.61539 19.3846L5 19.3846C4.44772 19.3846 4 18.9369 4 18.3846L4 14.7692M4.76918 18.6155L10.1538 13.2309M19.3844 8.61516L19.3844 4.99977C19.3844 4.44749 18.9367 3.99977 18.3844 3.99977L14.769 3.99977M18.6152 4.76904L13.2305 10.1537"
      stroke="black"
      stroke-opacity="0.2"
      stroke-width="1.5"
      stroke-linecap="round"
    />
  </svg>
)
export default GalleryIcon
