const handleDownload = async ({ fileUrl, fileName }: { fileUrl: string; fileName: string }) => {
  try {
    const response = await fetch(fileUrl, { credentials: "include" })

    if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`)

    const blob = await response.blob()
    const blobUrl = window.URL.createObjectURL(blob)

    const link = document.createElement("a")
    link.href = blobUrl
    link.download = fileName
    document.body.appendChild(link)
    link.click()

    // Cleanup
    document.body.removeChild(link)
    window.URL.revokeObjectURL(blobUrl)
  } catch (error) {
    console.error("Download failed:", error)
  }
}

export { handleDownload }
