export {
  fetchFilters,
  fetchJobs,
  postApply,
  type Job,
  type JobFilter,
  type JobFilters,
  type JobsObject,
} from "./api"
export { cutString } from "./cutString"
export { getCountryItems, type CountryItem } from "./extractCountry"
export { extractImage } from "./extractImage"
export { extractMenuProperties } from "./extractMenuProperties"
export { extractProperty } from "./extractProperty"
export { findProperty } from "./findProperty"
export { schemaCommon } from "./Form/FormSchema"
export {
  getAlternateMetadataFromHeader,
  getAlternateMetadataFromPathname,
} from "./getAlternateMetadataFromHeader"
export { getBreadCrumbs } from "./getBreadCrumbs"
export { getNonUmbracoUrl } from "./getNonUmbracoUrl"
export { getUmbracoMediaUrl } from "./getUmbracoMediaUrl"
export { getUmbracoPDFUrl } from "./getUmbracoPDFUrl"
export { getUmbracoUrl } from "./getUmbracoUrl"
export { handleDownload } from "./handleDownload"
export { extractMetaTags } from "./metaTags"
export { type PriorityData } from "./priorityApartments"
export {
  extractFilters,
  extractProjectsAndFilters,
  extractSingleProject,
  type Apartment,
  type NumbersObject,
  type ProjectFilter,
  type ProjectFilters,
  type ProjectPage,
} from "./projects"
export { ReactQueryContext } from "./ReactQueryContext"
export { extractSearchResults } from "./searchResults"
export * from "./types"
export { useApi } from "./useApi"
export { useOutsideClick } from "./UseOutsideClick"
export { matchFetchedChildren, type Group, type Groups, type MatchedStatuses } from "./utils"

export {
  aboutUsFilter,
  activeArmsClick,
  articlesClick,
  buttonClick,
  contactButtonClick,
  faqClickClose,
  faqClickOpen,
  filterClick,
  footerMenuClick,
  iconClick,
  jobClick,
  jobFilter,
  jobFormClick,
  jobFormErrorClick,
  jobFormSuccessClick,
  mainMenuClick,
  pageStripClick,
  pageview,
  searchItemClick,
  submitClick,
  submitError,
  submitSuccess,
} from "./gtm"

export { getFavicons } from "./getFavicons"

export { getLastPath } from "./getLastUrlPath"

export * from "./redirectsCache"

export * from "./JsonLdSchema"
