"use client"

import { useTheme } from "@emotion/react"
import styled from "@emotion/styled"
import { extractProperty } from "utils"
import Container from "./Container"
import NewText from "./NewText/NewText"
import NumbersGrid, { NumbersGridBackgroundProps } from "./NumbersComponent/NumbersGrid"
import SectionTitle from "./SectionTitle"
import { sharedColors } from "./theme/colors"

const StyledPaddings = styled.div`
  padding: 32px 0;
  ${(props) => props.theme.breakpoints.up("md")} {
    padding: 60px 0;
  }
`
type Props = {
  data: any
  accentColor?: string
  background?: string
  textColor?: string
  backgroundColor?: string
  titleColor?: string
  backgroundColors?: NumbersGridBackgroundProps
  showIcons?: boolean
}

export const NumbersComponent: React.FC<Props> = ({
  data,
  accentColor: defaultAccentColor,
  background = "white",
  textColor = "white",
  titleColor = "black",
  backgroundColors,
  showIcons,
}) => {
  const theme = useTheme()
  const accentColor = defaultAccentColor || theme.colors.propBlue
  const title = extractProperty(data, "blockTitle")
  const blockText = extractProperty(data, "blockText")
  const bottomeText = extractProperty(data, "bottomText")
  const content = extractProperty(data, "content")

  const blockContent = extractProperty(data, "blockContent")
  const contentItems = blockContent.map((contentBlock: any) => {
    return {
      title: extractProperty(contentBlock, "title"),
      number: extractProperty(contentBlock, "number"),
      textNextToNumber: extractProperty(contentBlock, "textNextToNumber"),
      text: extractProperty(contentBlock, "text"),
    }
  })

  return (
    <Container>
      <StyledPaddings>
        <SectionTitle
          title={title}
          titleColor={titleColor}
          accentColor={accentColor}
          hideIndicator
          subtitle={content}
          component={"h2"}
        />
        {blockText && (
          <NewText
            desktopFontSize={16}
            desktopFontWeight="400"
            desktopLineHeight="125%"
            mobileFontSize={14}
            mobileFontWeight="400"
            mobileLineHeight="125%"
            component="p"
            color={textColor}
          >
            {blockText}
          </NewText>
        )}
        <NumbersGrid
          data={contentItems}
          background={background}
          textColor={textColor}
          backgroundColors={backgroundColors}
          showIcons={showIcons}
        />
        <NewText
          desktopFontSize={14}
          desktopFontWeight="400"
          desktopLineHeight="20px"
          mobileFontSize={12}
          mobileFontWeight="400"
          mobileLineHeight="17px"
          component="p"
          color={sharedColors.black}
          marginTop={"32px"}
        >
          {bottomeText}
        </NewText>
      </StyledPaddings>
    </Container>
  )
}
export default NumbersComponent
